@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic);
/* .default-theme{
  --primary-text-color: #e3e3e3;
  --secondary-text-color: #e3e3e3;
  
  --primary-background-color: #31353D;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #3a3f48;
  --sidebar-box-shadow-color: #282c33;

  --close-button-color: #bdbdbd;
  --close-button-accent-color: #ffffff;

  --menu-header-color: #6c7b88;
  --menu-selected-color: #ffffff;
  --menu-hover-button-color: #16c7ff;
  --menu-hover-button-shadow-color: #16c7ff80;

  // Green Theme
  --primary-text-color: #414141;
  --secondary-text-color: #414141;
  --accent-text-color: #ffffff;
  --accent-text-hover-color: #acacac;
  
  --primary-background-color: #ffffff;
  --primary-main-background-color: #f4f4f4;
  --accent-background-color: #5d854e;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #cfcfcf;
  --sidebar-box-shadow-color: #e0e0e0;

  --close-button-color: var(--accent-text-color);
  --close-button-accent-color: #bbbbbb;

  --menu-header-text-color: #6c7b88;
  --menu-selected-color: #649155;
  --menu-hover-button-color: #7AA86A;
  --menu-hover-button-shadow-color: #3ef70080;
  --icon-background-color: #ffffff;
} */

:root {
  --primary-text-color: #414141;
  --secondary-text-color: #414141;
  --accent-text-color: #ffffff;
  --accent-text-hover-color: #c2e6ff;
  
  --primary-background-color: #ffffff;
  --primary-main-background-color: #f4f4f4;
  --accent-background-color: #3f79a0;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #cfcfcf;
  --sidebar-box-shadow-color: #e0e0e0;

  --close-button-color: var(--accent-text-color);
  --close-button-accent-color: #bbbbbb;

  --menu-header-text-color: #6c7b88;
  --menu-selected-color: #0094f7;
  --menu-hover-button-color: #43adf3;
  --menu-hover-button-shadow-color: #43adf380;
  --icon-background-color: #ffffff;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  10% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  30% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  40% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  60% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  70% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes sonar {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes sonar {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.page-wrapper .page-content .pad,
.page-wrapper .page-content .page-header,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  background-color: #f4f4f4;
  background-color: var(--primary-main-background-color);
  min-height: 100vh;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
}

.page-wrapper .page-content .pad {
  padding: 10px 10px 10px 75px;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {  
  .page-wrapper .page-content .pad {
    padding: 20px 20px 20px 85px;
  }

  .page-wrapper.toggled .page-content .pad {
    padding-left: 280px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: -8px;
  top: 0px;
  width: 70px;
  transition-delay: 0.3s;
  z-index: 1000;
}
.page-wrapper.toggled #show-sidebar {
  left: -75px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  font-weight: 350;
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1000;
  box-shadow: 2px 0px 5px -2px #3f79a0;
  box-shadow: 2px 0px 5px -2px var(--accent-background-color);
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 13px 21px 14px 21px;
  height: 51.75px;
  display: flex;
  align-items: center;
  background-color: #3f79a0;
  background-color: var(--accent-background-color);
  box-shadow: 5px 0px 5px #3f79a0;
  box-shadow: 5px 0px 5px var(--accent-background-color);
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.chiller-theme .sidebar-wrapper .sidebar-brand>a {
  color: #ffffff;
  color: var(--accent-text-color);

}

.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover {
  text-decoration: none;
  color: #c2e6ff;
  color: var(--accent-text-hover-color);
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  position:absolute;
  bottom: 0;
  width: 100%;
  height: 54px;
  padding: 10px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 42px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding: 10px 0px 10px 0px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  background-color: #ffffff;
  background-color: var(--icon-background-color);
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  -webkit-animation: swing ease-in-out 0.5s 1 alternate;
          animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 0;
  display: flex;
}

.sidebar-footer > a {
  position: relative;
  flex-grow: 1;
  font-size: 9px;
  line-height: 20px;
  text-align: center;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

/*--------------------------page-content-----------------------------*/

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  background: var(--scrollbar-background-color);
  border: 0px none #ffffff;
  border: 0px none var(--scrollbar-border);
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
  background: var(--scrollbar-background-color);
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
  background: var(--scrollbar-background-color);
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border: 0px none var(--scrollbar-border);
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: #ffffff;
    background: var(--primary-background-color);
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search {
    border-top: 1px solid #cfcfcf;
    border-top: 1px solid var(--accent-color);
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    text-decoration: none;
    color: #414141;
    color: var(--primary-text-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-footer>a:hover i {
    color: #414141;
    color: var(--secondary-text-color);
}

.page-wrapper.chiller-theme #close-sidebar {
    color: #ffffff;
    color: var(--close-button-color);
}

.page-wrapper.chiller-theme #close-sidebar:hover {
    color: #bbbbbb;
    color: var(--close-button-accent-color);
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper ul li:hover a span,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: #43adf3;
    color: var(--menu-hover-button-color);
    text-shadow:0px 0px 10px #43adf380;
    text-shadow:0px 0px 10px var(--menu-hover-button-shadow-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: #cfcfcf;
    background: var(--accent-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: #6c7b88;
    color: var(--menu-header-text-color);
}

.chiller-theme .sidebar-footer {
    background: #3f79a0;
    background: var(--accent-background-color);
    box-shadow: 0px 0px 1px #3f79a0;
    box-shadow: 0px 0px 1px var(--accent-background-color);
}

.chiller-theme .sidebar-footer>a {
  text-decoration: none;
  color: #ffffff;
  color: var(--accent-text-color);
}


.chiller-theme .sidebar-footer>a:hover {
  text-decoration: none;
  color: #c2e6ff;
  color: var(--accent-text-hover-color);
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

.selected-menu-item {
  color: #0094f7;
  color: var(--menu-selected-color);
}

.sidebar-menu .menu-divider {
  height: 1px;
  width: 100%;
  background: #3f79a0;
  background: var(--accent-background-color);
  box-shadow: 0px 0px 2px #3f79a0;
  box-shadow: 0px 0px 2px var(--accent-background-color);
  margin: 10px 0px 10px 0px;
}


.page-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  position: relative;
  top: 0;
  display: inline-block;
  margin: 0;
  padding: 11px 10px 8px 75px;
  width: 100%;
  min-height: 51.75px;
  overflow: hidden;
  color: #414141;
  color: var(--primary-text-color);
  background-color: #3f79a0;
  background-color: var(--accent-background-color);
  box-shadow: 5px 0px 5px #3f79a0;
  box-shadow: 5px 0px 5px var(--accent-background-color);
}

@media screen and (min-width: 768px) {  
  .page-header {
    padding: 11px 10px 8px 75px;
  }

  .page-wrapper.toggled .page-header {
    padding-left: 275px;
  }
}

.page-header h1 {
  float: left;
  color:#ffffff;
  color:var(--accent-text-color);
  width: auto;
  height: auto;
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.page-header i {
  color: white;
  float: right;
  width: auto;
  height: auto;
  font-size: 24px;
  padding: 0px 10px 0px 10px;
}

.page-header .header-btn {
  float: right;
  font-size: 20px;
  font-weight: 900;
  margin: 0px 0.15rem 0 0.75rem;
  padding: 0;
  text-align: center;
  color: #ffffff;
  color: var(--accent-text-color);
  cursor: pointer;
  height: auto;
  width: auto;
}

.page-header .searchBar {
  float: right;
  height: auto;
}


.leaflet-container {
    width: 100%;
    height: 98vh;
    padding-bottom: 10px;
    border-radius: 10px;
}

@media screen and (min-width: 768px) {  
    .leaflet-container {
        height: 92vh;
    }
}

.popup-entry {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    margin: 0 auto;
    padding: 3px;
    text-align: center;
}
.popup-entry input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 7px;
    box-sizing: border-box;
}
.popup-entry textarea {
    outline: 0;
    height: 75px;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 7px;
    padding: 0px 7px 7px 7px;
    box-sizing: border-box;
}
.popup-entry select {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 7px;
    box-sizing: border-box;
}
.popup-entry button {
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 55%;
    border: 0;
    padding: 7px;
    color: #FFFFFF;
    transition: all 0.3 ease;
    cursor: pointer;
}
.popup-entry button:hover,.popup-entry button:active,.popup-entry button:focus {
    background: #707070;
}
.popup-entry .message {
    margin: 0px 0 10px;
    color: #000000;
    font-size: 12px;
}
.popup-entry .message div {
    color: #474747 !important;
    cursor: pointer;
    text-decoration: none;
}

.Search {
    width: 275px;
    margin: 0 auto;
    position: relative;
    display: flex;
}
.SearchSpan {
    position: absolute;
    z-index: 998;
    width: 15%;
    border: 1px solid #474747;
    background: #474747;
    padding: 2px;
    text-align: center;
    color: #fff;
    border-radius: 5px 0 0 5px;
    font-size: 20px;
}
.SearchInput {
    position: absolute;
    z-index: 997;
    width: 85%;
    border: 1px solid #474747;
    border-left: none;
    padding: 5px 5px 5px 48px;
    border-radius: 5px;
    outline: none;
}
.cust-info {
    font-family: "Roboto", sans-serif;
    position: relative;
    z-index: 1;
    max-width: 240px;
    margin: 0 auto;
    padding: 7px;
    text-align: center;
}
.cust-info input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info textarea {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info select {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info button {
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
}
.cust-info button:hover,.cust-info button:active,.cust-info button:focus {
    background: #707070;
}
.dz-container {
}

.dz-container p {
    color: red;
    text-align: center;
}

.dz-drop-container {
    display: flexbox;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: auto;
    height: auto;
    border: 4px dashed #4aa1f3;
}

.dz-upload-icon {
    width: 50px;
    height: 50px;
    /* background: url(../images/upload.png) no-repeat center center;  */
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.dz-drop-message {
    text-align: center;
    color: #4aa1f3;
    font-family: Arial;
    font-size: 20px;
}

.dz-file-display-container {
    margin: auto;
    width: auto;
    height: auto;
}

.dz-file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 20px;
    position: relative;
    line-height: auto;
    height: auto;
}

.dz-file-status-bar > div {
    overflow: hidden;
}

.dz-file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.dz-file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 18px;
    color: #4aa1f3;
}

.dz-file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: auto;
    color: #9aa9bb;
}

.dz-file-error-message {
    color: red;
}

.dz-file-type-logo {
    width: 50px;
    height: 50px;
    /* background: url(../images/generic.png) no-repeat center center;  */
    background-size: 100%;
    position: absolute;
}

.dz-file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-right:auto;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.dz-file-remove  {
    display:inline-block;
    line-height: 25px;
    font-size: larger;
    cursor: pointer;
    color: red;
}

.profile-form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
}
.profile-form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.profile-form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    transition: all 0.3 ease;
    cursor: pointer;
}
.profile-form button:hover,.form button:active,.form button:focus {
    background: #707070;
}
.profile-form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
}
.profile-form .message a {
    color: #474747 !important;
    cursor: pointer;
    text-decoration: none;
}
.gallery-subset {
    padding: 0px 0px 25px 0px;
}

.gallery-subset h2 {
    font-size: 24px;
}

.gallery-item-pad {
    display: inline;
    padding: 10px;
}
.analytics h1 {
    font-size: 2rem;
}

.analytics h2 {
    font-size: 2rem;
    padding: 5px 10px 10px 10px;
}

.analytics h3 {
    padding: 10px 0px 2px 0px;
    font-size: 1rem;
}

.global-analytics {
    padding-bottom: 15px;
}

.red-text {
    color: white;
}
:root {
  --global-shadow-color: #969696;
}

body,
html {
  width: 100%;
  height: 100%;
}
  
body {
  font-family: 'Source Sans Pro';
}
 
.space {
  margin-top:10px;
}

.loading {
  margin: 0;
  position: absolute;
  top: 47.5%;
  left:47.5%;
  text-align: center;
}

.bg-test {
  background:rgba(0, 0, 0);
}

.border {
  border: #333333;
}

.label-padding {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: -5px;
}

.hide {
  display: none;
}
 
.label {
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 150%;
  font-size: .85em;
}
 
/* Heading Weight */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
 
/* Buttons */
.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700 !important;
  margin: 0.5rem;
}
 
.btn-dark {
  color: #fff !important;
}
 
.btn-xl {
  padding: 0.75rem 1.25rem !important;
}
 
/* Content Section */
.content-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
 
.content-section-heading h2 {
  font-size: 3rem;
}
 
.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
}

/* Footer Design */
footer.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
 
footer.footer .social-link {
  display: block;
  height: 4rem;
  width: 4rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color: #53728c/*#1D809F*/;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
 
footer.footer .social-link:hover {
  background-color: #474f52/*#155d74*/;
  text-decoration: none;
}

/* LOGIN PAGE */

.form-section {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #333333;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #707070;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #474747 !important;
  cursor: pointer;
  text-decoration: none;
}

.global-item-shadow {
  margin: 0px 0px 15px 0px;
  box-shadow: 4px 4px 10px #969696;
  box-shadow: 4px 4px 10px var(--global-shadow-color);
}

.material-list {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  padding: 10px;
}
