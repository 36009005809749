.analytics h1 {
    font-size: 2rem;
}

.analytics h2 {
    font-size: 2rem;
    padding: 5px 10px 10px 10px;
}

.analytics h3 {
    padding: 10px 0px 2px 0px;
    font-size: 1rem;
}

.global-analytics {
    padding-bottom: 15px;
}

.red-text {
    color: white;
}