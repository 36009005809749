.profile-form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 360px;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
}
.profile-form input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}

.profile-form button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}
.profile-form button:hover,.form button:active,.form button:focus {
    background: #707070;
}
.profile-form .message {
    margin: 15px 0 0;
    color: #b3b3b3;
    font-size: 12px;
}
.profile-form .message a {
    color: #474747 !important;
    cursor: pointer;
    text-decoration: none;
}