.gallery-subset {
    padding: 0px 0px 25px 0px;
}

.gallery-subset h2 {
    font-size: 24px;
}

.gallery-item-pad {
    display: inline;
    padding: 10px;
}