@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* .default-theme{
  --primary-text-color: #e3e3e3;
  --secondary-text-color: #e3e3e3;
  
  --primary-background-color: #31353D;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #3a3f48;
  --sidebar-box-shadow-color: #282c33;

  --close-button-color: #bdbdbd;
  --close-button-accent-color: #ffffff;

  --menu-header-color: #6c7b88;
  --menu-selected-color: #ffffff;
  --menu-hover-button-color: #16c7ff;
  --menu-hover-button-shadow-color: #16c7ff80;

  // Green Theme
  --primary-text-color: #414141;
  --secondary-text-color: #414141;
  --accent-text-color: #ffffff;
  --accent-text-hover-color: #acacac;
  
  --primary-background-color: #ffffff;
  --primary-main-background-color: #f4f4f4;
  --accent-background-color: #5d854e;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #cfcfcf;
  --sidebar-box-shadow-color: #e0e0e0;

  --close-button-color: var(--accent-text-color);
  --close-button-accent-color: #bbbbbb;

  --menu-header-text-color: #6c7b88;
  --menu-selected-color: #649155;
  --menu-hover-button-color: #7AA86A;
  --menu-hover-button-shadow-color: #3ef70080;
  --icon-background-color: #ffffff;
} */

:root {
  --primary-text-color: #414141;
  --secondary-text-color: #414141;
  --accent-text-color: #ffffff;
  --accent-text-hover-color: #c2e6ff;
  
  --primary-background-color: #ffffff;
  --primary-main-background-color: #f4f4f4;
  --accent-background-color: #3f79a0;
  --scrollbar-background-color: #525965;
  --scrollbar-border: #ffffff;

  --accent-color: #cfcfcf;
  --sidebar-box-shadow-color: #e0e0e0;

  --close-button-color: var(--accent-text-color);
  --close-button-accent-color: #bbbbbb;

  --menu-header-text-color: #6c7b88;
  --menu-selected-color: #0094f7;
  --menu-hover-button-color: #43adf3;
  --menu-hover-button-shadow-color: #43adf380;
  --icon-background-color: #ffffff;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.page-wrapper .page-content .pad,
.page-wrapper .page-content .page-header,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  background-color: var(--primary-main-background-color);
  min-height: 100vh;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  overflow-x: hidden;
}

.page-wrapper .page-content .pad {
  padding: 10px 10px 10px 75px;
}

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {  
  .page-wrapper .page-content .pad {
    padding: 20px 20px 20px 85px;
  }

  .page-wrapper.toggled .page-content .pad {
    padding-left: 280px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: -8px;
  top: 0px;
  width: 70px;
  transition-delay: 0.3s;
  z-index: 1000;
}
.page-wrapper.toggled #show-sidebar {
  left: -75px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  font-weight: 350;
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1000;
  box-shadow: 2px 0px 5px -2px var(--accent-background-color);
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 13px 21px 14px 21px;
  height: 51.75px;
  display: flex;
  align-items: center;
  background-color: var(--accent-background-color);
  box-shadow: 5px 0px 5px var(--accent-background-color);
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
}

.chiller-theme .sidebar-wrapper .sidebar-brand>a {
  color: var(--accent-text-color);

}

.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover {
  text-decoration: none;
  color: var(--accent-text-hover-color);
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  position:absolute;
  bottom: 0;
  width: 100%;
  height: 54px;
  padding: 10px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic {
  float: left;
  width: 42px;
  padding: 2px;
  border-radius: 12px;
  margin-right: 15px;
  overflow: hidden;
}

.sidebar-wrapper .sidebar-header .user-pic img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.sidebar-wrapper .sidebar-header .user-info {
  float: left;
}

.sidebar-wrapper .sidebar-header .user-info > span {
  display: block;
}

.sidebar-wrapper .sidebar-header .user-info .user-role {
  font-size: 12px;
}

/*-----------------------sidebar-search------------------------*/

.sidebar-wrapper .sidebar-search > div {
  padding: 10px 20px;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding: 10px 0px 10px 0px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  background-color: var(--icon-background-color);
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------side-footer------------------------------*/

.sidebar-footer {
  position: absolute;
  width: 100%;
  height: 20px;
  bottom: 0;
  display: flex;
}

.sidebar-footer > a {
  position: relative;
  flex-grow: 1;
  font-size: 9px;
  line-height: 20px;
  text-align: center;
}

.sidebar-footer > a .notification {
  position: absolute;
  top: 0;
}

/*--------------------------page-content-----------------------------*/

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-background-color);
  border: 0px none var(--scrollbar-border);
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-background-color);
}
::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-background-color);
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none var(--scrollbar-border);
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}


/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
    background: var(--primary-background-color);
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search {
    border-top: 1px solid var(--accent-color);
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a {
    text-decoration: none;
    color: var(--primary-text-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-footer>a:hover i {
    color: var(--secondary-text-color);
}

.page-wrapper.chiller-theme #close-sidebar {
    color: var(--close-button-color);
}

.page-wrapper.chiller-theme #close-sidebar:hover {
    color: var(--close-button-accent-color);
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper ul li:hover a span,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
    color: var(--menu-hover-button-color);
    text-shadow:0px 0px 10px var(--menu-hover-button-shadow-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
    background: var(--accent-color);
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
    color: var(--menu-header-text-color);
}

.chiller-theme .sidebar-footer {
    background: var(--accent-background-color);
    box-shadow: 0px 0px 1px var(--accent-background-color);
}

.chiller-theme .sidebar-footer>a {
  text-decoration: none;
  color: var(--accent-text-color);
}


.chiller-theme .sidebar-footer>a:hover {
  text-decoration: none;
  color: var(--accent-text-hover-color);
}

.chiller-theme .sidebar-footer>a:first-child {
    border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
    border-right: none;
}

.selected-menu-item {
  color: var(--menu-selected-color);
}

.sidebar-menu .menu-divider {
  height: 1px;
  width: 100%;
  background: var(--accent-background-color);
  box-shadow: 0px 0px 2px var(--accent-background-color);
  margin: 10px 0px 10px 0px;
}


.page-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  position: relative;
  top: 0;
  display: inline-block;
  margin: 0;
  padding: 11px 10px 8px 75px;
  width: 100%;
  min-height: 51.75px;
  overflow: hidden;
  color: var(--primary-text-color);
  background-color: var(--accent-background-color);
  box-shadow: 5px 0px 5px var(--accent-background-color);
}

@media screen and (min-width: 768px) {  
  .page-header {
    padding: 11px 10px 8px 75px;
  }

  .page-wrapper.toggled .page-header {
    padding-left: 275px;
  }
}

.page-header h1 {
  float: left;
  color:var(--accent-text-color);
  width: auto;
  height: auto;
  font-size: 24px;
  margin: 0;
  font-weight: 500;
  text-transform: uppercase;
}

.page-header i {
  color: white;
  float: right;
  width: auto;
  height: auto;
  font-size: 24px;
  padding: 0px 10px 0px 10px;
}

.page-header .header-btn {
  float: right;
  font-size: 20px;
  font-weight: 900;
  margin: 0px 0.15rem 0 0.75rem;
  padding: 0;
  text-align: center;
  color: var(--accent-text-color);
  cursor: pointer;
  height: auto;
  width: auto;
}

.page-header .searchBar {
  float: right;
  height: auto;
}

