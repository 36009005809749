.dz-container {
}

.dz-container p {
    color: red;
    text-align: center;
}

.dz-drop-container {
    display: flexbox;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: auto;
    height: auto;
    border: 4px dashed #4aa1f3;
}

.dz-upload-icon {
    width: 50px;
    height: 50px;
    /* background: url(../images/upload.png) no-repeat center center;  */
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
}

.dz-drop-message {
    text-align: center;
    color: #4aa1f3;
    font-family: Arial;
    font-size: 20px;
}

.dz-file-display-container {
    margin: auto;
    width: auto;
    height: auto;
}

.dz-file-status-bar{
    width: 100%;
    vertical-align:top;
    margin-top: 20px;
    position: relative;
    line-height: auto;
    height: auto;
}

.dz-file-status-bar > div {
    overflow: hidden;
}

.dz-file-type {
    display: inline-block!important;
    position: absolute;
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;
    margin-top: 25px;
    padding: 0 4px;
    border-radius: 2px;
    box-shadow: 1px 1px 2px #abc;
    color: #fff;
    background: #0080c8;
    text-transform: uppercase;
}

.dz-file-name {
    display: inline-block;
    vertical-align:top;
    margin-left: 18px;
    color: #4aa1f3;
}

.dz-file-error {
    display: inline-block;
    vertical-align: top;
    margin-left: auto;
    color: #9aa9bb;
}

.dz-file-error-message {
    color: red;
}

.dz-file-type-logo {
    width: 50px;
    height: 50px;
    /* background: url(../images/generic.png) no-repeat center center;  */
    background-size: 100%;
    position: absolute;
}

.dz-file-size {
    display:inline-block;
    vertical-align:top;
    color:#30693D;
    margin-right:auto;
    margin-left: 10px;
    color: #444242;
    font-weight: 700;
    font-size: 14px;
}

.dz-file-remove  {
    display:inline-block;
    line-height: 25px;
    font-size: larger;
    cursor: pointer;
    color: red;
}
