.cust-info {
    font-family: "Roboto", sans-serif;
    position: relative;
    z-index: 1;
    max-width: 240px;
    margin: 0 auto;
    padding: 7px;
    text-align: center;
}
.cust-info input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info textarea {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 5px 10px 5px 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info select {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 14px;
}
.cust-info button {
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 100%;
    border: 0;
    padding: 10px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}
.cust-info button:hover,.cust-info button:active,.cust-info button:focus {
    background: #707070;
}