@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic');

:root {
  --global-shadow-color: #969696;
}

body,
html {
  width: 100%;
  height: 100%;
}
  
body {
  font-family: 'Source Sans Pro';
}
 
.space {
  margin-top:10px;
}

.loading {
  margin: 0;
  position: absolute;
  top: 47.5%;
  left:47.5%;
  text-align: center;
}

.bg-test {
  background:rgba(0, 0, 0);
}

.border {
  border: #333333;
}

.label-padding {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: -5px;
}

.hide {
  display: none;
}
 
.label {
  display: flex;
  justify-content: center;
  text-align: center;
  line-height: 150%;
  font-size: .85em;
}
 
/* Heading Weight */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
 
/* Buttons */
.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700 !important;
  margin: 0.5rem;
}
 
.btn-dark {
  color: #fff !important;
}
 
.btn-xl {
  padding: 0.75rem 1.25rem !important;
}
 
/* Content Section */
.content-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
 
.content-section-heading h2 {
  font-size: 3rem;
}
 
.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase;
}

/* Footer Design */
footer.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
 
footer.footer .social-link {
  display: block;
  height: 4rem;
  width: 4rem;
  line-height: 4.3rem;
  font-size: 1.5rem;
  background-color: #53728c/*#1D809F*/;
  transition: background-color 0.15s ease-in-out;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
 
footer.footer .social-link:hover {
  background-color: #474f52/*#155d74*/;
  text-decoration: none;
}

/* LOGIN PAGE */

.form-section {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #333333;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #707070;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #474747 !important;
  cursor: pointer;
  text-decoration: none;
}

.global-item-shadow {
  margin: 0px 0px 15px 0px;
  box-shadow: 4px 4px 10px var(--global-shadow-color);
}

.material-list {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  padding: 10px;
}