.leaflet-container {
    width: 100%;
    height: 98vh;
    padding-bottom: 10px;
    border-radius: 10px;
}

@media screen and (min-width: 768px) {  
    .leaflet-container {
        height: 92vh;
    }
}

.popup-entry {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    margin: 0 auto;
    padding: 3px;
    text-align: center;
}
.popup-entry input {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 7px;
    box-sizing: border-box;
}
.popup-entry textarea {
    outline: 0;
    height: 75px;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 7px;
    padding: 0px 7px 7px 7px;
    box-sizing: border-box;
}
.popup-entry select {
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 7px;
    box-sizing: border-box;
}
.popup-entry button {
    text-transform: uppercase;
    outline: 0;
    background: #333333;
    width: 55%;
    border: 0;
    padding: 7px;
    color: #FFFFFF;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
}
.popup-entry button:hover,.popup-entry button:active,.popup-entry button:focus {
    background: #707070;
}
.popup-entry .message {
    margin: 0px 0 10px;
    color: #000000;
    font-size: 12px;
}
.popup-entry .message div {
    color: #474747 !important;
    cursor: pointer;
    text-decoration: none;
}

.Search {
    width: 275px;
    margin: 0 auto;
    position: relative;
    display: flex;
}
.SearchSpan {
    position: absolute;
    z-index: 998;
    width: 15%;
    border: 1px solid #474747;
    background: #474747;
    padding: 2px;
    text-align: center;
    color: #fff;
    border-radius: 5px 0 0 5px;
    font-size: 20px;
}
.SearchInput {
    position: absolute;
    z-index: 997;
    width: 85%;
    border: 1px solid #474747;
    border-left: none;
    padding: 5px 5px 5px 48px;
    border-radius: 5px;
    outline: none;
}